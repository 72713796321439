// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-legal-document-js": () => import("/home/circleci/repo/src/templates/legal-document.js" /* webpackChunkName: "component---src-templates-legal-document-js" */),
  "component---src-pages-404-js": () => import("/home/circleci/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-index-js": () => import("/home/circleci/repo/src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-index-js": () => import("/home/circleci/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

